import { Box, Link, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../../SupportingFiles/colors";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

function MLoginPopup({
  open,
  setOpen,
  showEnterMobileSection,
  setShowEnterMobileSection,
  loginFields,
  setLoginFields,
  fieldsUpdateState,
  handleShowMessage,
  showMessage,
  resendOtp,
}: {
  open: boolean;
  setOpen: any;
  showEnterMobileSection: boolean;
  setShowEnterMobileSection: any;
  loginFields: any;
  setLoginFields: any;
  fieldsUpdateState: Function;
  handleShowMessage: () => void;
  showMessage: Function;
  resendOtp: Function;
}) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, []);

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "#ffffff",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "84px",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          {/* topnav */}
          <Box
            sx={{
              // backgroundColor: "rgba(255, 243, 244, 1)",
              height: "60px",
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "9999",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              className="row"
              spacing={3}
              alignItems="center"
              width="100%"
            >
              <Grid xs={12} paddingLeft={"40px"} paddingRight={"40px"}>
                <Button
                  onClick={() => {
                    setShowEnterMobileSection(true);
                    setOpen(false);
                    setLoginFields({
                      enterMobile: { value: "", warning: false },
                      enterOtp: { value: "", warning: false },
                    });
                  }}
                  sx={{
                    position: "absolute",
                    right: "12px",
                    top: "19px",
                    maxWidth: "22px",
                    minWidth: "22px",
                    maxHeight: "22px",
                    color: COLORS.white,
                    backgroundColor: COLORS.primary,
                    borderRadius: "60px",
                    transition: "all ease-in-out 0.2s",
                    "&:hover": {
                      backgroundColor: COLORS.darkGrey,
                      transform: "rotate(-90deg)",
                    },
                  }}
                >
                  <CloseIcon style={{ height: "18px", width: "18px" }} />
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* contentsec */}
          <Box>
            {showEnterMobileSection ? (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                  <img
                    src="./images/login_popup_img.svg"
                    alt=""
                    style={{ maxWidth: "150px" }}
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"}>
                  <h4 style={{ fontSize: "20px", marginBottom: "8px" }}>
                    Customer Login
                  </h4>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "24px",
                      fontSize: "12px",
                      lineHeight: "18px",
                    }}
                  >
                    Hey, Enter your details to get sign in to your account
                  </p>
                </Grid>
                <Grid xs={8}>
                  <RKTextField
                    class_name=" mb-6"
                    title={"Enter Mobile Number"}
                    value={loginFields.enterMobile.value}
                    attrName={[
                      "enterMobile",
                      "value",
                      loginFields,
                      setLoginFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={loginFields.enterMobile.warning}
                    error_message={
                      isEmpty(loginFields.enterMobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                    max_length={10}
                    validation_type="NUMBER"
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="Send OTP"
                    onClick={handleShowMessage}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid xs={12} marginBottom={"24px"} textAlign={"center"}>
                  <img
                    src="./images/login_popup_img.svg"
                    alt=""
                    style={{ maxWidth: "150px" }}
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}
                  >
                    OTP sent successfully on{" "}
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      {loginFields.enterMobile.value}
                    </span>
                    <Link
                      sx={{
                        background: "rgba(242, 242, 242, 1)",
                        fontSize: "12px",
                        padding: "2px 10px",
                        borderRadius: "40px",
                        color: "rgba(51, 51, 51, 1)",
                        fontWeight: 500,
                        marginLeft: "12px",
                        display: "inline-block",
                        lineHeight: "18px",
                      }}
                      onClick={() => setShowEnterMobileSection(true)}
                    >
                      Edit
                    </Link>
                  </p>
                </Grid>
                <Grid xs={8}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter OTP"}
                    value={loginFields.enterOtp.value}
                    attrName={[
                      "enterOtp",
                      "value",
                      loginFields,
                      setLoginFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={loginFields.enterOtp.warning}
                    error_message={
                      isEmpty(loginFields.enterOtp.value)
                        ? "Enter OTP"
                        : "Enter Valid OTP"
                    }
                    max_length={4}
                    validation_type="NUMBER"
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"} marginBottom={"16px"}>
                  <CustomButton
                    className="primaryBtn "
                    text="Verify OTP"
                    onClick={() => {
                      setLoginFields({
                        ...loginFields,
                        enterOtp: { value: "", warning: false },
                      });
                      showMessage();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid xs={12}>
                  <p style={{ textAlign: "center" }}>
                    Didn’t receive?{" "}
                    <Link
                      sx={{ color: COLORS.primary, fontWeight: 600 }}
                      onClick={() => resendOtp()}
                    >
                      Resend OTP
                    </Link>
                  </p>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MLoginPopup;
