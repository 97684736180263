import React from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useMobile from "../../../SupportingFiles/MobileProvider";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import "../../../SCSS/ResponsivePages.scss";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

function Contact() {
  const isMobile = useMobile();

  const socialicons: Record<string, string> = {
    facebook: "./images/facebook.svg",
    twitter: "./images/twitter.svg",
    instagram: "./images/instagram.svg",
    linkedin: "./images/linkedin.svg",
    youtube: "./images/youtube.svg",
  };

  const startInvest = [
    {
      icon: "./images/call-icon.svg",
      subhead: "Call Us",
      heading: "Request a call back",
      mail: false,
    },
    {
      icon: "./images/envelope-icon.svg",
      subhead: "Write us",
      heading: "partner@aiinsurancebazar.com",
      mail: true,
    },
  ];

  const contactInfo = [
    {
      icon: "./images/location-img.svg",
      heading: "We’re located at:",
      subhead:
        "Rahhe Insurance Brokers, B-5, Plot No 71/7, 3rd floor, Rama Road, Moti Nagar Industrial area, Near Dr. Polymers Delhi industrial area, New Delhi – 110015",
    },
    {
      icon: "./images/envelope-icon.svg",
      heading: "Leave us a message at:",
      email: "customersupport@aiinsurancebazar.com",
      subhead: "As soon as we can, we will respond to you.",
    },
    {
      icon: "./images/clock-icon.svg",
      heading: "Available here from:",
      subhead: "10:00 AM to 7:00 PM",
    },
  ];

  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="contact-header res_Section">
        <Grid className="row" container columnSpacing={3}>
          <Grid md={6} sm={6}>
            <h3 className="heading">Got a question for us?</h3>
            <p className="headerSub">Let us Assist you.</p>
            <h5 className="headingtxt">
              We would like to simplify everything about insurance with a
              seamless process.
            </h5>
          </Grid>
          <Grid md={6} sm={6}>
            <Box className="imgbox">
              <img
                src="./images/contactHeaderimg.svg"
                alt="contact-header"
                style={{ maxWidth: "447px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="contact-info res_Section">
        <Grid
          className="row"
          container
          columnSpacing={3}
          rowSpacing={{ xs: 3 }}
        >
          {contactInfo.map((d, i) => {
            return (
              <Grid md={4} key={i}>
                <Box className="infoBox">
                  <Box className="imgbox">
                    <img
                      src={d.icon}
                      alt={d.heading}
                      style={{ maxWidth: "30px" }}
                    />
                  </Box>

                  <Box className="infoDes">
                    <h4 className="heading mb-1">{d.heading}</h4>
                    <Link href={`mailto:${d.email}`}>{d.email}</Link>
                    <p className="h3-medium">{d.subhead}</p>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <Grid className="row" columnSpacing={3} container>
          <Grid xs={12}>
            <Box className="alreadyCustomer">
              <p className="h3-medium">
                Already holding policy with us. Want to Renew? Customer Support
                Number: <Link href="tel:9311011511">9311011511</Link> (10:00 AM
                to 7:00 PM)
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="start-insurance res_Section">
        <Grid className="row" container columnSpacing={3} alignItems="center">
          <Grid md={6} sm={6} xs={12}>
            <Box className="insureHead">
              <h2 className="mb-3">
                Start Your <span>Insurance Business</span>
              </h2>
              <img
                src="./images/insure-img.svg"
                alt="insureimg"
                style={{ maxWidth: "212px" }}
              />
            </Box>
          </Grid>
          <Grid md={6} sm={6} xs={12}>
            <Box className="howToStart">
              <p className="h3-medium">
                Want to start your insurance business with zero investment?
              </p>
              <Box className="startInvest">
                {startInvest.map((d, i) => {
                  return (
                    <Box key={i} className="startinfo">
                      <Box className="startImg">
                        <img
                          src={d.icon}
                          alt={d.heading}
                          style={{ maxWidth: "26px" }}
                        />
                      </Box>
                      <Box>
                        <p className="small mb-1">{d.subhead}</p>
                        {d.mail ? (
                          <h6 className="startHead">
                            <Link href={`mailto:${d.heading}`}>
                              {d.heading}
                            </Link>
                          </h6>
                        ) : (
                          <h6 className="startHead">{d.heading}</h6>
                        )}
                      </Box>
                      <ArrowForwardIosRoundedIcon />
                    </Box>
                  );
                })}
              </Box>

              <p>
                Partner Support Number: 
                <Link href="tel:9311011511" className="telLink">
                  9311011511
                </Link>
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="our-app res_Section">
        <Grid
          className="row"
          container
          columnSpacing={3}
          rowSpacing={{ xs: 3 }}
          alignItems="center"
        >
          <Grid md={5} sm={5}>
            <h3 className="lightHead">
              Our App will cover all of your insurance needs
            </h3>
          </Grid>
          <Grid md={7} sm={7}>
            <Grid container columnSpacing={3} gap="24px" alignItems="center">
              <Grid xs={3} sm={3}>
                <img
                  src="./images/our-app.svg"
                  alt="ourappimg"
                  style={{ maxWidth: "86px" }}
                />
              </Grid>
              <Grid xs={9} sm={9}>
                <Box className="our-appInfo">
                  <p className="h3-medium head">
                    4.1 Rating{" "}
                    <img
                      src="./images/ratings.svg"
                      alt="ratings"
                      style={{ maxWidth: "98px" }}
                    />
                  </p>
                  <p className="h3-medium head">2 Lakh +</p>
                  <p className="h3-medium">5K+ reviews</p>
                  <p className="h3-medium">App downloads</p>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className="our-office res_Section">
        <Grid
          className="row"
          container
          columnSpacing={3}
          rowSpacing={{ xs: 3 }}
        >
          <Grid md={6} sm={6} xs={12}>
            <Box>
              <h2>
                Our <span>Offices</span>
              </h2>
              <ul className="officeInfoList">
                <li className="h3-medium lightp">
                  AI Insurancebazar Corporate Office
                </li>
                <li className="h3-medium lightp">
                  Rahhe Insurance Brokers, B-5, Plot No 71/7, 3rd floor, Rama
                  Road, Moti Nagar Industrial area, Near Dr. Polymers Delhi
                  industrial area, New Delhi – 110015
                </li>
                <li>
                  <ul className="follow">
                    {Object.keys(socialicons).map((key) => (
                      <li>
                        <Link>
                          <img key={key} src={socialicons[key]} alt={key} />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid md={6} sm={6} xs={12}>
            {/* <Box className="officeMap">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.030860976508!2d77.03842502549409!3d28.4484860757658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d22919c1d2911%3A0xa69cab748d9d1f40!2sRenewBuy%20%7C%20Corporate%20Office!5e0!3m2!1sen!2sin!4v1721640565754!5m2!1sen!2sin"></iframe>
            </Box> */}
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Contact;
