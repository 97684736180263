import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import "./NavBar.scss";
import CustomButton from "../../../InputFields/CustomButton/CustomButton";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import LoginPopup from "./LoginPopup/LoginPopup";
import LoginContainer from "../../../../Container/MyAccount/LoginContainer/LoginContainer";

import { MY_ACCOUNT_ROUTES } from "../../../../Routing/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/Store/hooks";
import { SESSION_CONSTANTS } from "../../../../Services/Constants/SessionConstants";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import { HomeSlice } from "../../../../Store/Reducer/Home/HomeSlice";
import { UserProfileSlice } from "../../../../Store/Reducer/Account/UserProfileSlice";

function NavBar() {
  // const [open, setOpen] = useState(false);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };

  const dispatch = useAppDispatch();
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);

  const handleClose = () => {
    setAnchorE2(null);
  };

  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };

  return (
    <Box className="navbar">
      <LoginContainer open={openLoginPopup} setOpen={setOpenLoginPopup} />
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        alignItems="center"
        className="row"
      >
        <Grid xs={3}>
          <img
            src="./images/logo.svg"
            height="62px"
            alt="AIInsurancebazar"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(COMMON_ROUTES.HOME);
            }}
          />
        </Grid>
        <Grid xs={9}>
          <Box className="topNavBox">
            <ul className="nav-list">
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.ABOUT);
                  }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Button
                  className="nav-link product-link"
                  id="basic-link"
                  aria-controls={isMenuOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={isMenuOpen ? "true" : undefined}
                  onClick={handleMenuClick}
                  // onMouseEnter={handleMenuClick}
                  disableRipple
                  sx={{
                    padding: 0,
                    fontWeight: 400,
                    textTransform: "capitalize",
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontFamily: "Poppins",
                    display: "inline-flex",
                    alignItems: "center",
                    "&:after": {
                      content: "''",
                      position: "absolute",
                      bottom: "-5px",
                      left: "0",
                      height: "2px",
                      borderRadius: "30px",
                      width: 0,
                      transition: "width 0.3s ease",
                    },
                    "&:hover": {
                      background: "none",
                      "&:after": {
                        width: "97%",
                      },
                    },
                  }}
                >
                  Insurance
                  <KeyboardArrowDownRoundedIcon sx={{ marginLeft: "4px" }} />
                </Button>
                <Menu
                  className="popoverMenu"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-link",
                    sx: {
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontFamily: "jioType",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                    }}
                  >
                    Health Insurance
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_TERM);
                    }}
                  >
                    Term Insurance
                  </MenuItem>
                </Menu>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.CONTACT);
                  }}
                >
                  Contact
                </Link>
              </li>
              <li>
                <CustomButton
                  className="primaryBtn"
                  text="Become an Advisor"
                  variant="contained"
                  onClick={() => {
                    navigate(COMMON_ROUTES.ADVISOR);
                  }}
                />
              </li>
              {USER_DATA.mobile.value ? (
                <li className="ctaBtn">
                  <Button
                    className="greenMdBtn rounded"
                    id="basic-button"
                    aria-controls={anchorE2 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorE2)}
                    onClick={(e) => {
                      dispatch(HomeSlice.actions.setPageStatus("MY_ACCOUNT"));
                      handleAccountClick(e);
                    }}
                  >
                    Hi! User
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorE2}
                    open={Boolean(anchorE2)}
                    onClose={handleClose}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      // onClick={() => {
                      //   handleClose();
                      //   window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                      // }}
                      onClick={() => {
                        handleClose();
                        const encodedMobile = btoa(USER_DATA.mobile.value);
                        sessionStorage.setItem(
                          SESSION_CONSTANTS.USER_MOBILE_NO,
                          encodedMobile
                        );
                        window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${encodedMobile}`;
                      }}
                    >
                      My account
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </li>
              ) : (
                <li>
                  <CustomButton
                    className="primaryBtn medium"
                    text="login"
                    variant="contained"
                    onClick={() => setOpenLoginPopup(true)}
                  />
                </li>
              )}
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NavBar;
