import React from "react";
import useMobile from "../../../SupportingFiles/MobileProvider";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";

function About() {
  const isMobile = useMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="policy-header termsheader res_Section">
        <Grid className="row" columnSpacing={3} container alignItems={"center"}>
          <Grid sm={6}>
            <img
              src="./images/aboutimg.svg"
              alt="aboutimg"
              style={{ maxWidth: "416px" }}
            />
          </Grid>
          <Grid sm={6}>
            <h1 className="mb-4">About Us</h1>
            <p style={{ color: "#fff" }}>
              AIinsurancebazar is one of the fastest-growing online distribution
              platforms, which offers a wide choice of life and general
              insurance policies, we have dedicated ourselves to providing
              comprehensive insurance solutions to meet the diverse needs of
              individuals and families.
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box className="textContent-pages res_Section">
        <Grid className="row" columnSpacing={3} container marginBottom={"24px"}>
          <Grid md={12}>
            <h4 className="mb-3">Our Mission</h4>
            <p style={{ marginBottom: "0" }}>
              Our mission is to deliver simplified insurance and financial
              products to everyone in India. With the widest choice of insurance
              and financial products, we provide advisors who advise with our
              smart proprietary technology.
            </p>
          </Grid>
        </Grid>
        <Grid className="row" columnSpacing={3} container marginBottom={"24px"}>
          <Grid md={12}>
            <h4 className="mb-3">Looking Ahead</h4>
            <p style={{ marginBottom: "0" }}>
              As we look to the future, having an insurance and financial
              advisor to guide you through complicated buying processes,
              renewals, and underwriting is crucial to simplifying the product.
              we also remain committed to evolving and adapting technology to
              meet the ever-changing needs of our clients.
            </p>
          </Grid>
        </Grid>
        <Grid className="row" columnSpacing={3} container marginBottom={"24px"}>
          <Grid md={12}>
            <h4 className="mb-3">Our Presence</h4>
            <p style={{ marginBottom: "0" }}>
              Our network of dedicated agents in pan India, providing
              personalized service and local expertise to ensure you receive the
              best possible insurance solutions.
            </p>
          </Grid>
        </Grid>
        <Grid className="row" columnSpacing={3} container marginBottom={"24px"}>
          <Grid md={12}>
            <h4 className="mb-3">Digital Presence</h4>
            <p>
              In today’s digital age, we understand the importance of being
              accessible online. Our comprehensive digital platform allows you
              to manage your insurance needs conveniently from anywhere. Our
              online services include:
            </p>
            <ul>
              <li>
                <span style={{ fontWeight: 600 }}>Website:</span> Explore our
                products, get quotes, and manage your policies at
                [www.AIinsurancebazar.com].
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Customer Portal:</span> Access
                your account, view policy details, and file claims through our
                secure customer portal.
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid className="row" columnSpacing={3} container marginBottom={"24px"}>
          <Grid md={12}>
            <h4 className="mb-3">What AIinsurancebazar has to offer?</h4>
            <ul>
              <li>Wide choice of Insurance and financial products</li>
              <li>Deliver on your unique needs with smart tech</li>
              <li>Simplify the process</li>
              <li>
                Handle all of the complex, unsatisfying aspects of insurance and
                financial products on your behalf
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default About;
