import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import LoginPopup from "../../../Component/Desktop/Common/NavBar/LoginPopup/LoginPopup";
import MLoginPopup from "../../../Component/Mobile/Common/MNavBar/MLoginPopup/MLoginPopup";
import { MY_ACCOUNT_ROUTES } from "../../../Routing/Path/MyAccountRoutes";
import { ACCOUNT_SERVICES } from "../../../Services/Account/AccountServices";
import { SESSION_CONSTANTS } from "../../../Services/Constants/SessionConstants";
import { UserProfileSlice } from "../../../Store/Reducer/Account/UserProfileSlice";
import {
  validateMobileNumber,
  validateMobileOtp,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";

const LoginContainer = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [showEnterMobileSection, setShowEnterMobileSection] = useState(true);
  const [loginFields, setLoginFields] = useState<{
    enterMobile: { value: string; warning: boolean };
    enterOtp: { value: string; warning: boolean };
  }>({
    enterMobile: { value: "", warning: false },
    enterOtp: { value: "", warning: false },
  });

  const closeModal = () => {
    setOpen(false);
    setShowEnterMobileSection(true);
    setLoginFields({
      enterMobile: { value: "", warning: false },
      enterOtp: { value: "", warning: false },
    });
  };

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  const handleShowMessage = () => {
    let hasError = false;
    let data = { ...loginFields };
    if (!validateMobileNumber(data.enterMobile.value)) {
      hasError = true;
      data = {
        ...data,
        enterMobile: { value: data.enterMobile.value, warning: true },
      };
    }
    setLoginFields({ ...data });
    if (!hasError) {
      postMobileNum();
    }
  };

  const postMobileNum = () => {
    let dto = {
      type: "myAccount",
      pno: loginFields.enterMobile.value,
    };
    const onSuccess = (res: any) => {
      console.log("resOTP", res);

      if (res) {
        setLoginFields({
          ...loginFields,
          enterOtp: { value: "", warning: false },
        });
        if (!res.status) {
          setShowEnterMobileSection(false);
        } else {
          toast.error("Please check your Mobile number");
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCOUNT_SERVICES.accountLogin(onSuccess, onError, dto);
  };

  const showMessage = () => {
    let hasError = false;
    let data = { ...loginFields };
    if (!validateMobileOtp(data.enterOtp.value)) {
      hasError = true;
      data = {
        ...data,
        enterOtp: { value: data.enterOtp.value, warning: true },
      };
    }
    setLoginFields({ ...data });
    if (!hasError) {
      verifyOtp();
    }
  };

  const verifyOtp = () => {
    let dto = {
      pno: loginFields.enterMobile.value,
      otp: loginFields.enterOtp.value,
    };

    const onSuccess = (res: any) => {
      console.log(res);

      if (res) {
        if (!res.status && res.message === "Otp verify success") {
          closeModal();
          dispatch(
            UserProfileSlice.actions.setAccountUserDataMobile(
              loginFields.enterMobile.value
            )
          );
          const encodedMobile = btoa(loginFields.enterMobile.value);
          sessionStorage.setItem(
            SESSION_CONSTANTS.USER_MOBILE_NO,
            encodedMobile
          );
          const newUrl = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${encodedMobile}`;

          // Redirect to the new URL
          window.location.href = newUrl;
        } else {
          toast.error(res.message);
        }
      }
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ACCOUNT_SERVICES.accountLoginVerifyOtp(onSuccess, onError, dto);
  };

  const resendOtp = () => {
    let dto = {
      type: "myAccount",
      pno: loginFields.enterMobile.value,
    };
    const onSuccess = (res: any) => {
      console.log(res);

      if (res) {
        setLoginFields({
          ...loginFields,
          enterOtp: { value: "", warning: false },
        });
        if (!res.status) {
          toast.success("OTP sent successfully");
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCOUNT_SERVICES.accountLoginResendOtp(onSuccess, onError, dto);
  };

  return (
    <>
      {isMobile ? (
        <MLoginPopup
          open={open}
          setOpen={setOpen}
          showEnterMobileSection={showEnterMobileSection}
          setShowEnterMobileSection={setShowEnterMobileSection}
          loginFields={loginFields}
          setLoginFields={setLoginFields}
          fieldsUpdateState={fieldsUpdateState}
          handleShowMessage={handleShowMessage}
          showMessage={showMessage}
          resendOtp={resendOtp}
        />
      ) : (
        <LoginPopup
          open={open}
          setOpen={setOpen}
          showEnterMobileSection={showEnterMobileSection}
          setShowEnterMobileSection={setShowEnterMobileSection}
          loginFields={loginFields}
          setLoginFields={setLoginFields}
          fieldsUpdateState={fieldsUpdateState}
          handleShowMessage={handleShowMessage}
          showMessage={showMessage}
          resendOtp={resendOtp}
        />
      )}
    </>
  );
};

export default LoginContainer;
