import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MNavBar.scss";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import LoginContainer from "../../../../Container/MyAccount/LoginContainer/LoginContainer";
import { MY_ACCOUNT_ROUTES } from "../../../../Routing/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/Store/hooks";
import { SESSION_CONSTANTS } from "../../../../Services/Constants/SessionConstants";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import { HomeSlice } from "../../../../Store/Reducer/Home/HomeSlice";
import { UserProfileSlice } from "../../../../Store/Reducer/Account/UserProfileSlice";

function MNavBar() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);

  const dispatch = useAppDispatch();
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);

  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };

  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenMenu(open);
    };
  return (
    <Box className="mnavbar">
      <LoginContainer open={open} setOpen={setOpen} />
      <Grid columnSpacing={3} className="row" container>
        <Grid xs={6}>
          <img
            src="./images/logo.svg"
            height="44px"
            alt="AIInsurancebazar"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(COMMON_ROUTES.HOME);
            }}
            style={{ cursor: "pointer", maxWidth: "244px" }}
          />
        </Grid>
        <Grid xs={6}>
          <Box className="menucontainer">
            <Link
              className="hamburger-menu"
              onClick={toggleDrawer("left", true)}
            >
              <img
                src="./images/menu_icon.svg"
                alt="menuicon"
                style={{
                  maxWidth: "25px",
                }}
              />
            </Link>
          </Box>
        </Grid>
      </Grid>
      {/* sidemenu */}
      <SwipeableDrawer
        anchor="left"
        open={openMenu}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <Box className="menuWrapper">
          <Box className="menu-section">
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => {
                    navigate(COMMON_ROUTES.ABOUT);
                  }}
                >
                  About Us
                </Link>
              </li>
            </ul>
          </Box>
          <Box className="menu-section">
            <p className="menu-heading">Insurance</p>
            <ul className="subMenu">
              <li>
                <Link className="nav-link">Car Insurance</Link>
                <Link className="nav-link">Term Insurance</Link>
              </li>
            </ul>
          </Box>
          <Box className="menu-section pt-0">
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(COMMON_ROUTES.CONTACT);
                  }}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => {
                    navigate(COMMON_ROUTES.ADVISOR);
                  }}
                >
                  Become an Advisor
                </Link>
              </li>

              {USER_DATA.mobile.value ? (
                <>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={(e) => {
                        window.location.href = `${FRONTEND_DOMAIN}${
                          MY_ACCOUNT_ROUTES.DASHBOARD
                        }${btoa(USER_DATA.mobile.value)}`;
                      }}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" onClick={handleLogout}>
                      Logout
                    </Link>
                  </li>
                </>
              ) : (
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => {
                      setOpen(true);
                      setOpenMenu(false);
                    }}
                  >
                    login
                  </Link>
                </li>
              )}
            </ul>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default MNavBar;
