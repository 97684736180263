import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "../../SupportingFiles/colors";
import "./popup.scss";

interface PopupProps {
  open: boolean;
  handleClose: () => void;
  variant?: "xsmall" | "small" | "medium" | "large";
  title?: string;
  content: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({
  open,
  handleClose,
  variant = "medium",
  title,
  content,
}) => {
  const variantData: {
    [key in "xsmall" | "small" | "medium" | "large"]: string;
  } = {
    xsmall: "412px",
    small: "600px",
    medium: "800px",
    large: "100px",
  };

  return (
    <Dialog
      open={open}
      scroll={"body"}
      className="popup"
      sx={{
        ".MuiPaper-root": {
          // boxShadow: "0px 4px 20px rgba(0,0,0,0.15)",
          maxWidth: variantData[variant],
          width: "100%",
          borderRadius: "20px",
          overflow: "visible",
          "@media (max-width: 767px)": {
            maxWidth: "calc(100% - 24px)!important",
            margin: "12px",
          },
        },
      }}
    >
      {title === "" ? (
        <Box
          sx={{
            padding: "16px",
            position: "relative",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "24px",
              maxWidth: "30px",
              minWidth: "30px",
              maxHeight: "30px",
              color: COLORS.white,
              backgroundColor: COLORS.primary,
              borderRadius: "60px",
              transition: "all ease-in-out 0.2s",
              "&:hover": {
                backgroundColor: COLORS.darkGrey,
                transform: "rotate(-90deg)",
              },
              "@media (max-width: 767px)": {
                maxWidth: "22px",
                minWidth: "22px",
                maxHeight: "22px",
              },
            }}
          >
            <CloseIcon style={{ height: "18px", width: "18px" }} />
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#feeddf",
            borderRadius: "20px 20px 0 0",
            padding: "20px 24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            "@media (max-width: 767px)": {
              backgroundColor: "transparent",
              padding: "12px 12px 0px",
            },
          }}
        >
          <h4>{title}</h4>
          <Button
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "24px",
              maxWidth: "30px",
              minWidth: "30px",
              maxHeight: "30px",
              color: COLORS.white,
              backgroundColor: COLORS.primary,
              borderRadius: "60px",
              transition: "all ease-in-out 0.2s",
              "&:hover": {
                backgroundColor: COLORS.darkGrey,
                transform: "rotate(-90deg)",
              },
              "@media (max-width: 767px)": {
                maxWidth: "22px",
                minWidth: "22px",
                maxHeight: "22px",
              },
            }}
          >
            <CloseIcon style={{ height: "18px", width: "18px" }} />
          </Button>
        </Box>
      )}
      {content}
    </Dialog>
  );
};

export default Popup;
